import React from 'react';
import '../styles/workprocess.scss'
import {Box} from "@mui/material";

const WorkProcess = () => {
    const steps = [
        {
            title: 'Заявка',
            content: [
                'Вы оставляете заявку на сайте или звоните по телефону'
            ]
        },
        {
            title: 'Выезд на объект',
            content: [
                'В удобное для вас время мастер приезжает на объект для замера и просчета'
            ]
        },
        {
            title: 'Согласование сметы',
            content: [
                'Перед началом работ вы знаете точную стоимость с учетом материала и сроки выполнения заказа'
            ]
        },
        {
            title: 'Подготовка',
            content: [
                'Проведение подготовительных работ и закупка материала, при необходимости'
            ]
        },
        {
            title: 'Процесс работы',
            content: [
                'Выполнение работ, при необходимости присылаю фото отчеты.'
            ]
        },
        {
            title: 'Сдача проекта',
            content: [
                'Кочество выполнения работ соответствует всем нормам пожарной безопасности и безопасны для регулярной эксплуотации'
            ]
        },
    ];

    return (
        <section className={'process_section'}>
            <div className="container">
                <h2>Прозрачный и понятный
                    процесс работы</h2>


                <Box className={'process_container'}
                     sx={{
                         '@media (max-width: 767px)': {
                             overflowX: 'scroll',
                             overflowY: 'hidden',
                             whiteSpace: 'nowrap',
                             '&::-webkit-scrollbar': {
                                 height: '4px',
                             },
                             '&::-webkit-scrollbar-track': {
                                 backgroundColor: '#E6E3DB',
                                 borderRadius: '0',
                             },
                             '&::-webkit-scrollbar-thumb': {
                                 backgroundColor: '#8C9648',
                                 borderRadius: '0',
                                 '&:hover': {
                                     backgroundColor: '#555',
                                 },
                             },
                         },
                     }}
                >
                    <div className={'mob_scroll'}>
                        {steps.map((step, index) => (
                            <div className="process_item" key={index}>
                                <span>{index + 1}</span>
                                <div>
                                    <p className={'step_title'}>{step.title}</p>
                                    <p className={'step_content'}>{step.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </Box>

            </div>
        </section>
    );
};

export default WorkProcess;