import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Projects from "../components/Projects";

const ProjectsPage = () => {


    return (
        <HelmetProvider>
            <Helmet>
                <title>Печник в Донецке, Макеевке и области | Анатолий Мельник </title>
                <meta name="description" content='Ознакомтесь с готовыми работами печника. Большая коллекция проектов мангалов и барбекю, отопительно - варочных печей, каминов. А так же каскады, сауны и облицовка диким камнем.' />
                <meta property="og:url" content="/projects" />
            </Helmet>

            <Projects />

        </HelmetProvider>
    );
};

export default ProjectsPage;