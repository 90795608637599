import React from 'react';
import '../styles/slick.scss'
import '../styles/section_head.scss'
import Slider from "react-slick";

const images = require.context('../img/stoves', false, /\.(webp|png|jpe?g|svg)$/);

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={'arrow_slider arrow_next'}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={'arrow_slider arrow_prev'}
            onClick={onClick}
        />
    );
}

const HeadSection = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const imagePaths = images.keys().map(key => images(key));



    return (
        <section className={'header_section container'}>
            <div className="head_title">
                <h1>Кладка <span className={'acсent'}>мангалов, каминов, печей</span> в Донецке и области</h1>

                <div className="head_btn">
                    <a href="/#contactForm" className={'cta_btn'}>РАСЧИТАТЬ СТОИМОСТЬ РАБОТ</a>
                    <a href="https://t.me/+79494307071" className={'telegram'} target="_blank"></a>
                </div>
            </div>
            <div className="head_slider">
                <Slider {...settings}>
                    {imagePaths.map((image, index) => (
                        <div className={'slide_head'}>
                            <img key={index} src={image} alt={`Slide ${index}`} className="slide-large"/>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default HeadSection;