import React, { useState } from 'react';
import '../styles/contactform.scss';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [form, setForm] = useState({
        name: "",
        contact: "",
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const patterns = {
        tel: {
            pattern: /^\+?[0-9\s-]{8,}$/,
            keyPattern: /[0-9+\s-]/,
        },
        text: {
            pattern: /^[a-zA-Zа-яА-Я0-9\s@\-_]+$/,
            keyPattern: /[a-zA-Zа-яА-Я0-9\s@\-_]/,
        },
        textarea: {
            pattern: /^[a-zA-Zа-яА-Я0-9\s@\-_]+$/,
            keyPattern: /[a-zA-Zа-яА-Я0-9\s@\-_]/,
        }
    };

    const validateField = (value, type) => {
        if (type === 'textarea' && !value) {
            return true;
        }
        return patterns[type]?.pattern.test(value);
    };

    const handleChange = (event) => {
        setSuccessMessage('');
        const { name, value, type } = event.target;
        const isValid = validateField(value, type);
        setForm(prevForm => ({ ...prevForm, [name]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
    };

    const handleKeyDown = (event) => {
        setSuccessMessage('');
        const { key, type, ctrlKey, metaKey } = event;
        const pattern = patterns[type]?.keyPattern;

        if (pattern && !pattern.test(key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab"].includes(key) && !ctrlKey && !metaKey) {
            event.preventDefault();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValidForm = Object.keys(form).every(key => {
            if (key === 'contact') {
                return validateField(form[key], 'tel');
            } else if (key === 'message') {
                if (form[key].trim() === '') {
                    return true; // Пропускаем проверку, если значение message пустое
                } else {
                    return validateField(form[key], 'textarea');
                }
            } else {
                return validateField(form[key], 'text');
            }
        });

        if (!isValidForm || !form.contact) {
            setErrors({
                name: !validateField(form.name, 'text'),
                contact: !validateField(form.contact, 'tel'),
            });
            setSuccessMessage('');
            return;
        }

        try {
            document.querySelector('.loader').style.display = 'block';

            await emailjs.send(
                'service_ocu3ghj',
                'template_gehvvt3',
                {
                    from_name: form.name,
                    to_name: 'nikisshlife@gmail.com',
                    subject: 'New contact request',
                    message: `Имя:${form.name}\n Контакт: ${form.contact}\nСообщение: ${form.message}`
                },
                { publicKey: 'Sl71C1XEkewEcME1q' }
            );

            document.querySelector('.loader').style.display = 'none';

            setSuccessMessage('Спасибо за запрос, я свяжусь с Вами!');
            setForm({ name: "", contact: "", message: "" });
            setErrors({});
        } catch (error) {
            document.querySelector('.loader').style.display = 'none';
            setErrors({ form: 'Что-то пошло не так, письмо не отправлено' });
        }
    };

    return (
        <section className='container' id={'contactForm'}>
            <div className='contact_section'>
                <div className="form_col contect_form_container">
                    <h2>Узнать стоимость работ</h2>
                    <ul className="contact_list">
                        <li className='contact_phone'><a href="tel:+79494307071" className={'under'}>+7(949) 430-70-71</a></li>
                        <li className='contact_mail'><a href="mailto:Anatolmoulin1957@gmail.com" className={'under'}>Anatolmoulin1957@gmail.com</a></li>
                        <li className='contact_adress'>г. Донецк</li>
                    </ul>
                </div>
                <div className="form_col form_container">
                    <form onSubmit={handleSubmit} noValidate>
                        <input
                            placeholder='Ваше имя'
                            type='text'
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className={errors.name ? 'error' : ''}
                        />
                        <input
                            placeholder='Ваш телефон'
                            type='tel'
                            value={form.contact}
                            name="contact"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className={errors.contact ? 'error' : ''}
                        />
                        <textarea
                            placeholder='Что вы хотите построить?'
                            rows="4"
                            value={form.message}
                            name="message"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className={errors.message ? 'error' : ''}
                        />
                        {Object.values(errors).some(error => error) && <p className="error_message">Заполните поля корректно</p>}
                        {successMessage && <p className="success_message">{successMessage}</p>}
                        <button type='submit' className='form_send'>Оставить заявку
                        <span className="loader"></span>
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
