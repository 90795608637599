import React from 'react';
import Slider from "react-slick";
import '../../styles/slick.scss'


const SliderProject = ({ settings, images }) => {
    return (
        <div className="projects_container imageStove">
                <Slider {...settings}>
                    {images}
                </Slider>
        </div>
    );
};

export default SliderProject;
