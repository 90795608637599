import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/projectssection.scss';
import '../styles/project_page.scss';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CustomArrow from "./subComponents/CustomArrow";

const barbecue = require.context('../img/projects/barbecue', false, /\.(webp|png|jpe?g|svg)$/);
const fireplace = require.context('../img/projects/fireplace', false, /\.(webp|png|jpe?g|svg)$/);
const stove = require.context('../img/projects/stove', false, /\.(webp|png|jpe?g|svg)$/);
const sauna = require.context('../img/projects/sauna', false, /\.(webp|png|jpe?g|svg)$/);

const oblicovka = require.context('../img/projects/oblicovka', false, /\.(webp|png|jpe?g|svg)$/);
const cascad = require.context('../img/projects/cascad', false, /\.(webp|png|jpe?g|svg)$/);

const extractNumber = (filename) => {
    const match = filename.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
};

const Projects = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const imageIndexFromUrl = query.get('image');
    const tabFromUrl = query.get('tab') || 'barbecue';

    const imageStove = {
        'barbecue': barbecue.keys().map(key => barbecue(key)),
        'fireplace': fireplace.keys().map(key => fireplace(key)),
        'stove': stove.keys().map(key => stove(key)),
        'sauna': sauna.keys().map(key => sauna(key)),
        'oblicovka': oblicovka.keys().map(key => oblicovka(key)),
        'cascad': cascad.keys().map(key => cascad(key))
    };

    const [activeTab, setActiveTab] = useState(tabFromUrl);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [showAllImages, setShowAllImages] = useState(false);
    const [sortedImages, setSortedImages] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 991);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (imageIndexFromUrl) {
            const index = parseInt(imageIndexFromUrl, 10);
            if (!isNaN(index) && sortedImages[index]) {
                setCurrentImage(sortedImages[index]);
                setIsPopupOpen(true);
            }
        }
    }, [imageIndexFromUrl, sortedImages]);

    useEffect(() => {
        const images = imageStove[activeTab].map(key => {
            return { path: key, number: extractNumber(key) };
        }).sort((a, b) => a.number - b.number).map(item => item.path).reverse();

        setSortedImages(images);
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setShowAllImages(false);
        navigate({ search: `?tab=${tab}` });
    };

    const handleImageClick = (image, index) => {
        setCurrentImage(image);
        setIsPopupOpen(true);
        navigate({ search: `?tab=${activeTab}&image=${index}` });
    };

    const handleSelectChange = (event) => {
        handleTabClick(event.target.value);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setCurrentImage(null);
        navigate({ search: `?tab=${activeTab}` });
    };

    const toggleShowAllImages = () => {
        setShowAllImages(!showAllImages);
    };

    const images = sortedImages.map((image, index) => (
        <div className={'project_item'} key={index}>
            <img src={image} alt={`Slide ${index}`} className="slide-large" onClick={() => handleImageClick(image, index)} />
        </div>
    ));

    return (
        <section className={'projects_section container project_page'} id={'projects'}>
            <div className={'projects_head_nav'}>
                <h1>Готовые проекты: <br /><span className={'acсent'}>Камины, Мангалы, Отопительные и варочные Печи и Сауны </span>
                </h1>

                {isMobile ? (
                    <FormControl>
                        <InputLabel id="projects-select-label">Тип проекта</InputLabel>
                        <Select
                            labelId="projects-select-label"
                            id="projects-select"
                            value={activeTab}
                            label="Выберите проект"
                            onChange={handleSelectChange}
                            MenuProps={{
                                classes: { paper: 'select-menu' }
                            }}
                            IconComponent={CustomArrow}
                        >
                            <MenuItem value={'barbecue'}>Мангалы</MenuItem>
                            <MenuItem value={'fireplace'}>Камины</MenuItem>
                            <MenuItem value={'stove'}>Печи</MenuItem>
                            <MenuItem value={'sauna'}>Сауны</MenuItem>
                            <MenuItem value={'oblicovka'}>Облицовка</MenuItem>
                            <MenuItem value={'cascad'}>Каскад</MenuItem>
                        </Select>
                    </FormControl>
                ) : (
                    <div className={'desctop_nav'}>
                        <button
                            className={activeTab === 'barbecue' ? 'active' : ''}
                            onClick={() => handleTabClick('barbecue')}>Мангалы
                        </button>
                        <button
                            className={activeTab === 'fireplace' ? 'active' : ''}
                            onClick={() => handleTabClick('fireplace')}>Камины
                        </button>
                        <button
                            className={activeTab === 'stove' ? 'active' : ''}
                            onClick={() => handleTabClick('stove')}>Печи
                        </button>
                        <button
                            className={activeTab === 'sauna' ? 'active' : ''}
                            onClick={() => handleTabClick('sauna')}>Сауны
                        </button>
                        <button
                            className={activeTab === 'oblicovka' ? 'active' : ''}
                            onClick={() => handleTabClick('oblicovka')}>Облицовка
                        </button>
                        <button
                            className={activeTab === 'cascad' ? 'active' : ''}
                            onClick={() => handleTabClick('cascad')}>Каскад
                        </button>
                    </div>
                )}
            </div>

            <div className="projects_container">
                {images}
            </div>

            {isPopupOpen && (
                <div className="popup" onClick={closePopup}>
                    <div className="popup-content">
                        <span className={'close'}></span>
                        <img src={currentImage} alt="Popup" onClick={closePopup} />
                    </div>
                </div>
            )}
        </section>
    );
};

export default Projects;
