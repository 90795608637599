import React from 'react';

const CustomArrow = () => {
    return (
        <div className="custom-arrow-icon">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.87551 0V12.9097L1.57529 6.95891L0 8.49957L8.99954 17L18 8.50043L16.4229 7.01251L10.1227 12.9097V0L7.87551 0Z" fill="#8C9648" fill-opacity="0.72549"/>
            </svg>
        </div>
    );
};

export default CustomArrow;
