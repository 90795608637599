import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import HeadSection from "../components/HeadSection";
import WorkProcess from "../components/WorkProcess";
import ProjectsSection from "../components/ProjectsSection";
import ServiceSection from "../components/ServiceSection";
import ContactForm from "../components/ContactForm";

const HomePage = ({ scrollToSection }) => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Отопительно - варочные печи, мангалы и камины в Донецке, Макеевке и области | Услуги Печника</title>
                <meta name="description" content='Кладка новых печей, чистка и ремонт печей, дизайнерские мангалы из декоративного камня или кирпича, камины, сауны. Печник с более 15 лет опыта. Гарантия качества и безопасности.'/>
                <meta property="og:url" content="/"/>
            </Helmet>

            <HeadSection/>
            <WorkProcess/>
            <ProjectsSection/>
            <ServiceSection/>
            <ContactForm />

        </HelmetProvider>
    );
};

export default HomePage;