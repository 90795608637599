import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ErrorPage = () => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Frontend Developer</title>
                <meta name="description" content='wfwf' />
                <meta property="og:url" content="adfsf" />
            </Helmet>

        </HelmetProvider>
    );
};

export default ErrorPage;