import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../img/logo.svg';
import '../../styles/header.scss';

const Header = () => {
    const [isOpen, setOpen] = useState(false);
    const [isScrolled, setScrolled] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // mobile hamburger animation
    const handleClick = () => {
        setOpen(!isOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleMenuClick = (e, path) => {
        e.preventDefault();
        if (path.startsWith('/#')) {
            const targetId = path.replace('/#', '');
            if (location.pathname !== '/') {
                navigate(`/#${targetId}`);
            } else {
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                    setOpen(false); // Close menu after click
                }
            }
        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (location.hash) {
            const targetId = location.hash.replace('#', '');
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <header className={`section-nav ${isOpen ? 'open' : ''} ${isScrolled ? 'active' : ''}`}>
            <div className="container-header navigation">
                <a href="/">
                    <img src={Logo} alt="Web developer" className="logo" />
                </a>

                <nav className={`${isOpen ? 'active' : ''}`}>
                    <ul>
                        <li><a href="/projects" onClick={(e) => handleMenuClick(e, '/projects')}>Проекты</a></li>
                        <li><a href="/#services" onClick={(e) => handleMenuClick(e, '/#services')}>Услуги</a></li>
                        <li><a href="/#contactForm" onClick={(e) => handleMenuClick(e, '/#contactForm')}>Контакты</a></li>
                    </ul>
                    <div className="media_mobile">
                        <a href="tel:+79494307071" className={'under mobile_contact'}>+7(949) 430-70-71</a>
                    </div>
                </nav>

                <div className="head_contacts">
                    <a href="tel:+79494307071" className={'under'}>+7(949) 430-70-71</a>
                </div>

                <div className="hamburger">
                    <svg className={`ham hamRotate ham8 ${isOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="80"
                         onClick={handleClick}>
                        <path className="line top"
                              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"/>
                        <path className="line middle" d="m 30,50 h 40"/>
                        <path className="line bottom"
                              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"/>
                    </svg>
                </div>
            </div>
        </header>
    );
};

export default Header;
