import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import '../src/styles/main.scss'
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import ErrorPage from "./pages/ErrorPage";
import Layout from "./components/layout/Layout";

export default function App() {

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage/>}/>
                        <Route path="/projects" element={<ProjectsPage/>}/>
                        <Route path="*" element={<ErrorPage/>}/>
                    </Route>
                </Routes>
            </div>
        </Router>
    );
}

