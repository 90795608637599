import React from 'react';
import Slider from "react-slick";
import '../styles/servicesection.scss'

import Imgbarb from '../img/services/barbecue.jpg'
import Imgfire from '../img/services/fireplace.jpg'
import Imgsauna from '../img/services/sauna.jpg'
import Imgstove from '../img/services/stove.jpg'
import Kaskad from '../img/services/kaskad.jpg'
import Сladding from '../img/services/obli2.jpg'

function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <div
            className={'arrow_slider arrow_next'}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <div
            className={'arrow_slider arrow_prev'}
            onClick={onClick}
        />
    );
}

const services = [
    {
        title: 'Мангалы',
        content: [
            'Мангалы и барбекю из кирпича по индивидуальному заказу'
        ],
        link: '/#contactForm',
        img: Imgbarb,
    },
    {
        title: 'Камины',
        content: [
            'Кладка каминов из кирпича. Ремонт и обслуживание каминов'
        ],
        link: '/#contactForm',
        img: Imgfire,
    },
    {
        title: 'Печи',
        content: [
            'Ремонт, чистка, кладка новых отопительных и варочных печей'
        ],
        link: '/#contactForm',
        img: Imgstove,
    },
    {
        title: 'Каскады/фонтаны',
        content: [
            'Каскады и декоративные фонтаны для частного дома'
        ],
        link: '/#contactForm',
        img: Kaskad,
    },
    {
        title: 'Сауны',
        content: [
            'Бани и сауны с дровяной печью. Разработка и реализация проекта'
        ],
        link: '/#contactForm',
        img: Imgsauna,
    },
    {
        title: 'Облицовка камнем',
        content: [
            'Облицовка диким камнем печей, каминов, заборов, домов'
        ],
        link: '/#contactForm',
        img: Сladding,
    },

];

const ServiceSection = () => {
    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    return (
        <section className={'service_section container'} id={'services'}>
            <div className={'service_head'}>
                <h2>Предоставляем
                    <span className={'acсent'}> широкий спектр услуг</span>
                </h2>

                <p><span>Гарантия на все работы.</span>
                    Консультация и расчет стоимости бесплатны</p>
            </div>

            <div className={'service_container'}>
                <Slider {...settings}>
                    {services.map((service, index) => (
                        <div className="service_item" key={index}>
                             <div className="service_img"
                             style={{backgroundImage: `url(${service.img})`}}>

                             </div>
                            <div className={'service_content'}>
                                <p className={'service_title'}>{service.title}</p>
                                <p className={'service_text'}>{service.content}</p>
                            </div>
                            <div className={'button_service'}>
                                <a href={service.link} className={'main_btn'}>Оставить заявку</a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default ServiceSection;