import React, { useState, useEffect } from 'react';
import '../styles/projectssection.scss'
import SliderProject from "./subComponents/SliderProject";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CustomArrow from "./subComponents/CustomArrow";

const barbecue = require.context('../img/projects/barbecue', false, /\.(webp|png|jpe?g|svg)$/);
const fireplace = require.context('../img/projects/fireplace', false, /\.(webp|png|jpe?g|svg)$/);
const stove = require.context('../img/projects/stove', false, /\.(webp|png|jpe?g|svg)$/);
const sauna = require.context('../img/projects/sauna', false, /\.(webp|png|jpe?g|svg)$/);

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={'arrow_slider arrow_next'}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className={'arrow_slider arrow_prev'}
            onClick={onClick}
        />
    );
}

// Функция для извлечения чисел из названий файлов
const extractNumber = (filename) => {
    const match = filename.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
};

const ProjectsSection = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    vertical: true,
                    verticalSwiping: true,
                }
            }
        ]
    };

    // Пути к изображениям
    const imageStove = {
        'barbecue': barbecue.keys().map(key => barbecue(key)),
        'fireplace': fireplace.keys().map(key => fireplace(key)),
        'stove': stove.keys().map(key => stove(key)),
        'sauna': sauna.keys().map(key => sauna(key))
    };

    const [activeTab, setActiveTab] = useState('barbecue'); // Изначально выбираем первый таб
    const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [showAllImages, setShowAllImages] = useState(false);
    const [sortedImages, setSortedImages] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 991);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Обновляем отсортированные изображения при изменении активного таба
        const images = imageStove[activeTab].map(key => {
            return { path: key, number: extractNumber(key) };
        }).sort((a, b) => a.number - b.number).map(item => item.path).reverse();

        setSortedImages(images);
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setShowAllImages(false);
    };

    const handleImageClick = (image) => {
        setCurrentImage(image);
        setIsPopupOpen(true);
    };

    const handleSelectChange = (event) => {
        handleTabClick(event.target.value);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setCurrentImage(null);
    };

    const toggleShowAllImages = () => {
        setShowAllImages(!showAllImages);
    };

    const images = sortedImages.map((image, index) => (
        <div className={'project_item'} key={index}>
            <img src={image} alt={`Slide ${index}`} className="slide-large" onClick={() => handleImageClick(image)}/>
        </div>
    ));

    const visibleImages = isMobile || showAllImages ? images : images.slice(0, 6);

    return (
        <section className={'projects_section container'} id={'projects'}>
            <div className={'projects_head_nav'}>
                <h2>Более <span className={'acсent'}>150 выполненых проектов </span>
                    по всему Донецку и области</h2>
                {isMobile ? (
                    <FormControl>
                        <InputLabel id="projects-select-label">Тип проекта</InputLabel>
                        <Select
                            labelId="projects-select-label"
                            id="projects-select"
                            value={activeTab}
                            label="Выберите проект"
                            onChange={handleSelectChange}
                            MenuProps={{
                                classes: { paper: 'select-menu' }
                            }}
                            IconComponent={CustomArrow}
                        >
                            <MenuItem value={'barbecue'}>Мангалы</MenuItem>
                            <MenuItem value={'fireplace'}>Камины</MenuItem>
                            <MenuItem value={'stove'}>Печи</MenuItem>
                            <MenuItem value={'sauna'}>Сауны</MenuItem>
                        </Select>
                    </FormControl>

                ) : (
                    <div className={'desctop_nav'}>
                        <button
                            className={activeTab === 'barbecue' ? 'active' : ''}
                            onClick={() => handleTabClick('barbecue')}>Мангалы
                        </button>
                        <button
                            className={activeTab === 'fireplace' ? 'active' : ''}
                            onClick={() => handleTabClick('fireplace')}>Камины
                        </button>
                        <button
                            className={activeTab === 'stove' ? 'active' : ''}
                            onClick={() => handleTabClick('stove')}>Печи
                        </button>
                        <button
                            className={activeTab === 'sauna' ? 'active' : ''}
                            onClick={() => handleTabClick('sauna')}>Сауны
                        </button>
                    </div>
                )}

            </div>

            <div className="projects_container">
                {isMobile ? (
                    <SliderProject settings={settings} images={images}/>
                ) : (
                    visibleImages
                )}
            </div>

            <div className={'projects_buttons'}>
                {!isMobile && images.length > 6 && (
                    <button className="more_item" onClick={toggleShowAllImages}>
                        {showAllImages ? 'Скрыть' : 'Показать еще'}
                    </button>
                )}
                <a href="/projects" className={'main_btn'}>Все проекты</a>
            </div>

            {isPopupOpen && (
                <div className="popup" onClick={closePopup}>
                    <div className="popup-content">
                        <span className={'close'}></span>
                        <img src={currentImage} alt="Popup" onClick={closePopup} />
                    </div>
                </div>
            )}
        </section>
    );
};

export default ProjectsSection;
