import React from 'react';
import Logo from '../../img/logo.svg'
import '../../styles/footer.scss'

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className={'container-footer section-footer'}>
            <div className="container">
                <div className="footer-container">
                    <div className={'footer-col logo-col'}>
                        <img src={Logo} alt="Web developer" className="logo" />
                        <p className={'footer_text'}>Мангалы, камины, отопительные и варочные печи в Донецке, Макеевке и области.</p>
                        <p className={'copyright'}>© Печник 2015 - {currentYear} </p>
                    </div>
                    <div className={'footer-col footer-col-menu'}>
                        <ul>
                            <li><a href="/#projects" className={'under'}>Проекты</a></li>
                            <li><a href="/#services" className={'under'}>Услуги</a></li>
                            <li><a href="/#contactForm" className={'under'}>Контакты</a></li>
                        </ul>
                    </div>
                    <div className={'footer-col footer-col-contact'}>
                        <div className={'contact-container'}>
                            <div className={'contact-element tel'}>
                                <a href="tel:+79494307071" className={'under'}>+7(949) 430-70-71</a>
                            </div>
                            <div className={'contact-element mail'}>
                                <a href="mailto:Anatolmoulin1957@gmail.com" className={'under'}>Anatolmoulin1957@gmail.com</a>
                            </div>
                            <div className={'contact-element geo'}>
                                г. Донецк
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;